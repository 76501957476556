import { Controller } from "@hotwired/stimulus"
import { Chart, BarController, BarElement, PointElement, CategoryScale, LinearScale, Title } from 'chart.js'

Chart.register(BarController, BarElement, PointElement, CategoryScale, LinearScale, Title);

export default class extends Controller {
  static values = { labels: String, data: String, datasetTitle: String, chartTitle: String, minScale: Number, maxScale: Number }

  connect() {
    const labelsArray = this.labelsValue.split(',')
    const dataArray = this.dataValue.split(',').map(v => parseInt(v))
    const min = this.minScaleValue
    const max = this.maxScaleValue
    const data = {
      labels: labelsArray,
      datasets: [{
        data: dataArray,
        borderColor: '#c1ebcd',
        backgroundColor: '#c1ebcd',
      }],
    }
    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          }
        },
        scales: {
          y: {
            type: 'linear',
            min: min,
            max: max,
          }
        },
      }
    }
    const ctx = this.element.getContext('2d');
    new Chart(ctx, config);
  }
}