import { Turbo } from "@hotwired/turbo-rails"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "../javascripts/bootstrap.bundle"
import "channels"
import "controllers"
import "../stylesheets/bootstrap"
import "../stylesheets/application"
import "../stylesheets/activities"
import "../stylesheets/reports"
import 'bootstrap-icons/font/bootstrap-icons.css'
const images = require.context("../images", true)
const imagePath = name => images(name, true)

Turbo.start()
ActiveStorage.start()
require("trix")
require("@rails/actiontext")