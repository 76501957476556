import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['query', 'filtered']
  filter() {
    this.filteredTargets.forEach((element, index) => {
      const includesText = element.dataset.text.toLowerCase().includes(this.query)
      element.hidden = !includesText
      if (element.dataset.disable != null) {
        document.getElementById(element.dataset.disable).disabled = element.hidden
      }
    })
  }
  get query() {
    return this.queryTarget.value.toLowerCase();
  }
}